import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { UserRole } from 'utils/user-role';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'assessment-requests',
    loadChildren: () => import('./pages/assessment-request/assessment-request.module').then((m) => m.AssessmentRequestModule),
    canActivate: [AuthGuardService],
    data: { roles: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.OWNER, UserRole.GENERAL_COMMITTEE] },
  },
  {
    path: 'requests',
    loadChildren: () => import('./pages/request/request.module').then((m) => m.RequestModule),
    canActivate: [AuthGuardService],
    data: { roles: [UserRole.SYS_ADMIN, UserRole.STAFF, UserRole.COMMITTEE, UserRole.GENERAL_COMMITTEE] },
  },
  {
    path: 'schedule-calendar',
    loadChildren: () => import('./pages/schedule-calendar/schedule-calendar.module').then((m) => m.ScheduleCalendarModule),
    canActivate: [AuthGuardService],
    data: { roles: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.STAFF] },
  },
  {
    path: 'tracking',
    loadChildren: () => import('./pages/tracking/tracking.module').then((m) => m.TrackingModule),
    canActivate: [AuthGuardService],
    data: { roles: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.STAFF, UserRole.OWNER, UserRole.GENERAL_COMMITTEE] },

  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'appointment-letter',
    loadChildren: () => import('./pages/reviewer-list/reviewer-list.module').then((m) => m.ReviewerListModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuardService],
    data: { roles: [UserRole.SYS_ADMIN, UserRole.STAFF, UserRole.ADMIN] },
  },
  {
    path: 'user-management',
    loadChildren: () => import('./pages/import-data/import-data.module').then((m) => m.ImportDataModule),
    canActivate: [AuthGuardService],
    data: { roles: [UserRole.SYS_ADMIN, UserRole.STAFF, UserRole.ADMIN] },
  },
  {
    path: '**',
    redirectTo: UserRole.GENERAL_COMMITTEE ? '/requests' : '/assessment-requests',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
