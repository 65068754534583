import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SweetAlertsService } from 'app/services/sweet-alerts.service';
import { environment } from 'environments/environment';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-file-upload-box',
  templateUrl: './file-upload-box.component.html',
  styleUrls: ['./file-upload-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadBoxComponent),
      multi: true,
    },
  ],
})
export class FileUploadBoxComponent implements OnInit, ControlValueAccessor {
  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  subscription: Subscription;

  @Input() name = GenerateRandom(6);
  @Input() label: string;
  @Input() limitLabel: string;
  @Input() limitSize: number = 100000000; // 100MB
  @Input() multiple: boolean = false;

  @Input() set fileName(value) {
    this.displayFile = value;
  }

  files: NgxFileDropEntry[] = [];
  file: File;

  displayFile = '';
  hasDropZoneOver: boolean = false;

  form = new FormControl();

  onChange = (value: string) => {};
  onTouched = (value: string) => {};

  constructor(
    private translate: TranslateService,
    private swalService: SweetAlertsService
  ) {}

  ngOnInit(): void {
    this.subscription = this.form.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((val) => {
        const value = this.form.value;
        this.onChange(value);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    if (!value && this.file) {
      this.removeFile();
    }
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.hasDropZoneOver = false;

    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    const reader = new FileReader();
    fileEntry.file((file: File) => {
      if (file.size > this.limitSize) {
        const title = this.translate.instant(
          'SWEETALERT.WARNING_IMAGE_SIZE_TITLE'
        );
        const text = this.translate.instant(
          'SWEETALERT.WARNING_IMAGE_SIZE_TEXT',
          { value: this.limitLabel['value'], unit: this.limitLabel['unit'] }
        );
        this.swalService.swalWarning(title, text);
      } else {
        reader.readAsDataURL(file);
        // reader.onload = () => {
        //   this.imageUrl$.next(reader.result);
        // };
        this.form.setValue(file);
        this.file = file;
        this.displayFile = file.name;
      }
    });
  }

  removeFile() {
    this.form.reset();
    this.files.splice(0, 1);
    this.file = null;
    this.displayFile = '';
    // this.imageUrl$.next(null);
    // this.onDeleteImage.emit();
  }
}
