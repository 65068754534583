<ngx-datatable #table class="bootstrap core-bootstrap fixed-row" [rows]="sources.data" [columns]="columns" [columnMode]="mode" [rowHeight]="'auto'" [summaryHeight]="'auto'" [summaryRow]="enableSummary" [summaryPosition]="summaryPosition" [footerHeight]="sources.data?.length > 0 && sources.total !== undefined ? ((sources.total / (sources.limit ?? limit)) > 1) ? 57 : 42 : 0" [scrollbarH]="true" [externalSorting]="externalSorting" (sort)="onSortChange($event)" [loadingIndicator]="loading">
  <ngx-datatable-footer *ngIf="true">
    <ng-container *ngIf="sources.data?.length > 0">
      <ng-template ngx-datatable-footer-template>
        <div class="datatable-footer-custom justify-content-between flex-column flex-sm-row" [formGroup]="form">
          <div class="d-flex align-items-center w-100 mb-75 mb-sm-0" [ngClass]="{'justify-content-end':  sources.total  < 10, 'justify-content-between':  sources.total  > 10  }">
          <!-- <div class="d-flex align-items-center w-100 mb-75 mb-sm-0" [ngClass]="{'justify-content-end': !((sources.total / (sources.limit ?? limit)) > 1), 'justify-content-between': ((sources.total / (sources.limit ?? limit)) > 1) }"> -->
            <label class="d-flex align-items-center" [hidden]="sources.total  < 10">
            <!-- <label class="d-flex align-items-center" [hidden]="!((sources.total / (sources.limit ?? limit)) > 1)"> -->
              {{ 'TABLE.SHOW' | translate }}
              <select class="form-control form-control-sm line-height-condensed datatable-footer-row-fetch mx-50" formControlName="limit">
                <option *ngFor="let option of options" [value]="option.value">{{ option.value }}</option>
              </select>
              {{ 'TABLE.ENTRIES' | translate }}
            </label>

            <span class="text-muted" *ngIf="sources.total !== undefined">
              {{ 'TABLE.TOTAL_RESULT' | translate }} {{ sources.total }} {{ 'TABLE.TOTAL_LIST' | translate }}
            </span>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [page]="sources.page" [size]="sources.limit ?? limit" [count]="sources.total" [hidden]="!((sources.total / (sources.limit ?? limit)) > 1)" (change)="onPageChange($event)"></datatable-pager>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ngx-datatable-footer>
</ngx-datatable>

<ng-template #tableSummaryOrder let-row="row" let-value="value">
  {{ 'TABLE.SUMMARY_TOTAL' | translate }}
</ng-template>

<div *ngIf="sources.data?.length === 0" class="custom-empty-row">
  {{ 'TABLE.NO_DATA_DISPLAY' | translate }}
</div>
