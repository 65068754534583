import {
  GoogleLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'app/models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentToken: string;
  public currentUser: Observable<User>;

  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private http$: HttpClient,
    private router: Router,
    private socialAuthService: SocialAuthService
  ) {
    this.currentToken = localStorage.getItem('token');

    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  getToken() {
    return this.currentToken;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  isAuthenticated() {
    return this.currentToken ? true : false;
  }

  userHasRole(roles) {
    if (!roles) {
      return true;
    } else {
      return roles.includes(this.currentUserValue['role_name']);
    }
  }

  setAccessUser(response: any) {
    this.currentToken = response.access_token;
    this.currentUser = response.user;

    if (response.access_token) {
      response.user.clinic_id = response.clinic_id;
      localStorage.setItem('token', response.access_token);
      localStorage.setItem('currentUser', JSON.stringify(response.user));
      this.currentUserSubject.next(response.user);
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
    }
  }

  login(params: any) {
    return this.http$.post('/sessions', params).pipe(retry(1));
  }
  loginSSO(params: any) {
    return this.http$.post('/sessions/auth_sso', params).pipe(retry(1));
  }

  logout() {
    this.setAccessUser({ access_token: null });
    localStorage.removeItem('request_menu_count');
    localStorage.removeItem('request_inprogress_count');
    localStorage.removeItem('request_tab_count');
    this.refreshSSOToken();
    this.router.navigateByUrl('/auth');
  }

  refreshSSOToken() {
    this.socialAuthService
      .refreshAuthToken(GoogleLoginProvider.PROVIDER_ID)
      .then((token) => {});
    this.socialAuthService
      .refreshAccessToken(GoogleLoginProvider.PROVIDER_ID)
      .then((token) => {});
    this.socialAuthService.signOut(true);
  }
}
