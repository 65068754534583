<ng-container *ngIf="!item.hidden">

  <!-- item.url -->
    <a class="dropdown-item d-flex align-items-center" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl" [routerLink]="[item.url]" [target]="item.openInNewTab ? '_blank' : '_self'">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      <span *ngIf="item.id === 'request' && request_menu_count && request_menu_count.total > 0 " class="badge badge-pill badge-danger ml-50 notification-request-main">{{request_menu_count.total}}</span>
      <span *ngIf="item.id === 'assessment-requests' && request_menu_count && request_menu_count.in_progress > 0 " class="badge badge-pill badge-danger ml-50 notification-request-main">{{request_menu_count.in_progress}}</span>
    </a>

  <!-- item.externalUrl -->
  <a class="dropdown-item d-flex align-items-center" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl" [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <span [data-feather]="item.icon" *ngIf="item.icon"></span>
    <span [translate]="item.translate">{{ item.title }}</span>
  </ng-template>
</ng-container>
