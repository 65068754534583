import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { LabelMode } from 'utils/label-mode.enum';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-input-w-select',
  templateUrl: './input-w-select.component.html',
  styleUrls: ['./input-w-select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputWSelectComponent), multi: true }],
})
export class InputWSelectComponent implements OnInit, ControlValueAccessor {

  @Input() isDisabled;

  @Input()
  set error(value: any) {
    this.isErrorSelect = value[0] ?? false;
    this.isErrorInput = value[1] ?? false;
  }

  @Input() selectPlaceholder: string;
  @Input() selectName = GenerateRandom(6);
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() options: any = [];
  @Input() searchable = true;

  @Input() name = GenerateRandom(6);
  @Input() type = 'text';
  @Input() label: string;
  @Input() placeholder = '';
  @Input() mask: string;
  @Input() dropSpecialCharacters: boolean = true;
  @Input() thousandSeparator: string;
  @Input() minlength: number = 0;
  @Input() maxlength: number = 1000;

  @Input() debouceTime: number = 0;

  @Input() inputClass: string;
  @Input() direction = 'ltr'; //ltr, rtl

  @Input() errorMessage: string = 'VALIDATOR.REQUIRED';

  @Input() set labelMode(value: LabelMode) {
    this.mode = value;
  }

  // prefix must be in order as Selct, Input
  @Input() prefix: any = [];

  @Output() valueChange = new EventEmitter();

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  form: FormGroup;

  isErrorSelect = false;
  isErrorInput = false;


  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      [this.prefix[0]]: '',
      [this.prefix[1]]: ''
    });

    if (this.isDisabled) {
      this.form.disable();
    }

    this.form.valueChanges.pipe(debounceTime(this.debouceTime)).subscribe(value => {
      this.valueChange.emit(value);
      this.onChange(value);
    })
  }

  writeValue(obj: any): void {
    // Use patchValue fot unmatch key
    this.form.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
}
