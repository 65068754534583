<div class="card stats-card {{ classes }}">
  <div class="card-header p-1">
    <div>
      <h5 class="card-text mb-50">{{ label | translate }}</h5>
      <ng-container [ngTemplateOutlet]="info"></ng-container>
    </div>
    <!-- <div class="avatar p-50 m-0 {{ iconClass }}">
      <div class="avatar-content">
        <i [data-feather]="icon" class="font-medium-5"></i>
      </div>
    </div> -->
  </div>
</div>

<ng-template #info>
  <h2 class="font-weight-bolder mb-0" *ngIf="data !== null">
    <ng-container [ngTemplateOutlet]="dataDisplay"></ng-container>
  </h2>
</ng-template>

<ng-template #dataDisplay>
  <ng-container *ngIf="!html; else htmlEl">
    <ng-container *ngIf="!decimals; else decimalsEl">
      <ng-container *ngIf="!mask; else maskEl">{{ data }} {{ suffix | translate }}</ng-container>
    </ng-container>
  </ng-container>

  <ng-template #htmlEl>
    <span [innerHtml]="data" class="pre-line"></span>
  </ng-template>

  <ng-template #decimalsEl>
    {{ data | number: '1.'+decimals+'-'+decimals }} {{ suffix | translate }}
  </ng-template>

  <ng-template #maskEl>
    {{ data | mask: mask : separator }} {{ suffix | translate }}
  </ng-template>
</ng-template>