<ngx-file-drop
  class="drop-zone"
  [ngClass]="{ 'file-drop-over': hasDropZoneOver }"
  (onFileOver)="hasDropZoneOver = true"
  (onFileLeave)="hasDropZoneOver = false"
  (onFileDrop)="dropped($event)"
  [multiple]="multiple"
  [disabled]="form.disabled"
>
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector"
  >
    <p class="mb-0 text-muted" *ngIf="!displayFile">
      {{ "APP.FILE_PLACEHOLDER" | translate }}
      <a class="primary file-link" (click)="openFileSelector()">{{
        "COMPONENT.BROWSE_FILE" | translate
      }}</a>
    </p>
    <div class="file-display" [ngClass]="{ disabled: form.disabled }">
      <ng-container *ngIf="displayFile; else placeholder">
        <div class="d-flex">
          <p class="mb-0 mr-50">{{ displayFile }}</p>
          <a class="file-remove" (click)="removeFile()">
            <span [data-feather]="'x'"></span>
          </a>
        </div>
      </ng-container>
    </div>
  </ng-template>
</ngx-file-drop>
