import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CoreDirectivesModule } from '@core/directives/directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbDatepickerModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';

import { NgxMaskModule } from 'ngx-mask';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import { ScreenSizeDetectorComponent } from './screen-size-detector/screen-size-detector.component';
import { InputComponent } from './input/input.component';
import { InputWSelectComponent } from './input-w-select/input-w-select.component';
import { TextareaComponent } from './textarea/textarea.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { RadioComponent } from './radio/radio.component';
import { SelectComponent } from './select/select.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { InfoItemComponent } from './info-item/info-item.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { StatusLoadingComponent } from './status-loading/status-loading.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { FileUploadMultupleComponent } from './file-upload-multuple/file-upload-multuple.component';
import { FileUploadBoxComponent } from './file-upload-box/file-upload-box.component';
import { MatChipsModule } from '@angular/material/chips';
import { DatatableComponent } from './datatable/datatable.component';
import { StatusLoadingTwoComponent } from './status-loading-two/status-loading-two.component';
import { DaterangePickerComponent } from './daterange-picker/daterange-picker.component';
import { MonthpickerComponent } from './monthpicker/monthpicker.component';
import { ReportCardComponent } from './report-card/report-card.component';
import { InputMaskModule } from 'racoon-mask-raw';
@NgModule({
  declarations: [
    ScreenSizeDetectorComponent,
    InputComponent,
    InputWSelectComponent,
    TextareaComponent,
    DatepickerComponent,
    RadioComponent,
    SelectComponent,
    FileUploadComponent,
    InfoItemComponent,
    CheckboxComponent,
    StatusLoadingComponent,
    TimepickerComponent,
    FileUploadMultupleComponent,
    FileUploadBoxComponent,
    DatatableComponent,
    StatusLoadingTwoComponent,
    DaterangePickerComponent,
    MonthpickerComponent,
    ReportCardComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    CoreDirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgxMaskModule.forRoot(),
    NgxFileDropModule,
    MatChipsModule,
    NgxDatatableModule,
    NzDatePickerModule,
    InputMaskModule
  ],
  exports: [
    ScreenSizeDetectorComponent,
    InputComponent,
    InputWSelectComponent,
    TextareaComponent,
    DatepickerComponent,
    RadioComponent,
    SelectComponent,
    FileUploadComponent,
    InfoItemComponent,
    CheckboxComponent,
    StatusLoadingComponent,
    TimepickerComponent,
    FileUploadMultupleComponent,
    FileUploadBoxComponent,
    MatChipsModule,
    DatatableComponent,
    StatusLoadingTwoComponent,
    DaterangePickerComponent,
    MonthpickerComponent,
    ReportCardComponent
  ],
})
export class ComponentsModule { }
