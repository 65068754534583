<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="input"></ng-container>
</ng-container>

<ng-template #input>
  <ngb-timepicker [name]="name" [formControl]="form" [spinners]="spinners" [class]="modeClass" [ngClass]="{ 'is-invalid': isError }"></ngb-timepicker>
</ng-template>

<ng-template #default>
  <div class="form-group" [ngClass]="classes">
    <label [for]="name" [ngClass]="{ 'is-invalid': isError }">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
    <ng-container [ngTemplateOutlet]="error"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group form-group-inline" [ngClass]="classes">
    <label [for]="name" [ngClass]="{ 'is-invalid': isError }">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #error>
  <span class="error" *ngIf="isError">{{ errorMessage | translate }}</span>
</ng-template>
