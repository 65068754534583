import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(fullName: string): any {
    let fn = fullName?.split(' ');
    return [fn[0], fn[fn.length - 1]].map(n => n[0]).join('');
  }
}
