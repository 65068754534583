import { CoreMenu } from '@core/types';
import { UserRole } from 'utils/user-role';

export const menu: CoreMenu[] = [
  {
    id: 'assessment-requests',
    title: 'Assessment Requests',
    translate: 'MENU_TITLE.ASSESSMENT_REQUEST',
    type: 'item',
    icon: 'clipboard',
    url: 'assessment-requests',
    role: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.OWNER, UserRole.GENERAL_COMMITTEE],
    is_external: true,
  },
  {
    id: 'request',
    title: 'Request',
    translate: 'MENU_TITLE.REQUESTS',
    type: 'item',
    icon: 'check-square',
    url: 'requests',
    role: [UserRole.SYS_ADMIN, UserRole.STAFF, UserRole.COMMITTEE, UserRole.GENERAL_COMMITTEE],
  },
  {
    id: 'appointment-letter',
    title: 'Appointment letter',
    translate: 'MENU_TITLE.APPOINTMENT_LETTER',
    type: 'item',
    icon: 'file',
    url: 'appointment-letter',
    role: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.STAFF],
  },
  {
    id: 'schedule-calendar',
    title: 'Schedule Calendar',
    translate: 'MENU_TITLE.SCHEDULE_CALENDAR',
    type: 'item',
    icon: 'calendar',
    url: 'schedule-calendar',
    role: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.STAFF],
  },
  {
    id: 'tracking',
    title: 'Tracking',
    translate: 'MENU_TITLE.TRACKING',
    type: 'item',
    icon: 'search',
    url: 'tracking',
    role: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.STAFF],
  },
  // Report
  {
    id: 'reports',
    type: 'section',
    title: 'Reports',
    translate: 'MENU_TITLE.REPORTS.SECTION',
    icon: 'file-text',
    role: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.STAFF],
    children: [
      {
        id: 'requester-report',
        title: 'Requester Report',
        translate: 'MENU_TITLE.REPORTS.REQUESTER_REPORT',
        type: 'item',
        icon: 'file-minus',
        url: 'reports/requester-report',
        role: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.STAFF],
      },
      {
        id: 'committee-report',
        title: 'Committee Report',
        translate: 'MENU_TITLE.REPORTS.COMMITTEE_INDIVIDUAL_REPORT',
        type: 'item',
        icon: 'file-minus',
        url: 'reports/committee-report',
        role: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.STAFF],
      },
      {
        id: 'committee-summary-report',
        title: 'Committee Summary Report',
        translate: 'MENU_TITLE.REPORTS.COMMITTEES_REPORT',
        type: 'item',
        icon: 'file-minus',
        url: 'reports/committee-summary-report',
        role: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.STAFF],
      },
    ]
  },
  // =====
  {
    id: 'user-management',
    title: 'User Management',
    translate: 'MENU_TITLE.USER_MANAGEMENT',
    type: 'item',
    icon: 'users',
    url: 'user-management',
    role: [UserRole.SYS_ADMIN, UserRole.ADMIN, UserRole.STAFF],

  },
];
