import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'status-loading-two',
  templateUrl: './status-loading-two.component.html',
  styleUrls: ['./status-loading-two.component.scss']
})
export class StatusLoadingTwoComponent implements OnInit {
  @Input() isLoading: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
