import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ScreenResizeService } from 'utils/screen-resize.service';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatatableComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  @ViewChild('tableSummaryOrder', { static: true })
  tableSummaryOrder: TemplateRef<any>;

  public ColumnMode = ColumnMode;

  @Input() set datasource(value: any) {
    if (value.data) {
      value.data.forEach((r, i) => {
        r.order = value.offset + (i + 1);
      });
      this.sources = value;
    }
  }

  @Input() set isLoading(value: boolean) {
    this.loading = value;
  }

  @Input() columns: any = [];
  @Input() mode = this.ColumnMode.force;
  @Input() limit = 20;
  @Input() externalSorting = true;
  @Input() enableSummary = false;
  @Input() summaryPosition = 'bottom';

  @Output() pageChange = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  @Output() limitChange = new EventEmitter();

  form: FormGroup;

  sources: any = [];

  loading: any;

  screenSize: any;
  showMobileFooter: boolean = true;

  options = [
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];

  constructor(
    private scrRezService: ScreenResizeService,
    private fb: FormBuilder
  ) {
    this.screenSize = JSON.parse(localStorage.getItem('initScrSize'));
    setTimeout(() => {
      this.onScreenResize();
    }, 300);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      limit: new FormControl(this.limit),
    });

    this.subscriptions.push(
      this.scrRezService.onResize$.pipe(debounceTime(250)).subscribe((res) => {
        this.screenSize = res;
        this.onScreenResize();
      }),
      this.form.valueChanges.subscribe((value) => {
        this.limitChange.emit(value);
      })
    );

    if (!this.columns.find((c) => c.prop === 'order')) {
      this.columns.unshift({
        prop: 'order',
        name: '#',
        sortable: false,
        width: 64,
        minWidth: 64,
        maxWidth: 64,
        cellClass: 'text-center',
        headerClass: 'd-flex justify-content-center',
      });

      if (this.enableSummary) {
        this.columns[0].summaryTemplate = this.tableSummaryOrder;
      }
    }
    if (this.columns.find((c) => c.prop === 'option')) {
      this.columns.unshift(
        this.columns.splice(
          this.columns.findIndex((i) => i.prop === 'option')
        )[0]
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((i) => i.unsubscribe());
  }

  onPageChange(event) {
    this.loading = true;
    this.pageChange.emit({ paging: event });
  }

  onSortChange(event) {
    this.loading = true;
    this.sortChange.emit({ sort: event });
  }

  onScreenResize() {
    if (['xs', 'sm'].indexOf(this.screenSize) > -1) {
      this.showMobileFooter = this.sources.total <= this.sources.limit;
    } else {
      this.showMobileFooter = true;
    }
  }
}
