import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { apiPaths } from './_api-path';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  private api = apiPaths;
  private excepted_key_names = [];

  constructor(private http$: HttpClient) {}

  _setParams(value) {
    let params = new HttpParams();
    for (const key in value) {
      if (Array.isArray(value[key])) {
        params = params.append(key, JSON.stringify(value[key]));
      } else {
        params = params.append(key, value[key]);
      }
    }
    return params;
  }

  _setFormData(object_key, value) {
    let formData = new FormData();
    for (const key in value) {
      let obj = value[key];
      if (Array.isArray(obj)) {
        obj.forEach((e, i) => {
          if (this.excepted_key_names.includes(key)) {
            formData.append(`${object_key}[${key}][]`, e);
          } else {
            for (const key_i in e) {
              formData.append(`${object_key}[${key}][][${key_i}]`, e[key_i]);
            }
          }
        });
      } else {
        formData.append(`${object_key}[${key}]`, value[key]);
      }
    }
    return formData;
  }

  profile(): Observable<any> {
    return this.http$.get(`${this.api.profile}`).pipe(retry(1));
  }

  updateUser(id?, payload?): Observable<any> {
    const formData = this._setFormData('user', payload);
    return this.http$.put(`${this.api.users}/${id}`, formData).pipe(retry(1));
  }

  //User Notification
  notificationCount(): Observable<any> {
    return this.http$.get(`${this.api.notification_request_list}`).pipe(retry(1));
  }
  notificationMenu(): Observable<any> {
    return this.http$.get(`${this.api.notification_request_menu}`).pipe(retry(1));
  }
  notificationInprogress(): Observable<any> {
    return this.http$.get(`${this.api.notifications_inprogress_count}`).pipe(retry(1));
  }

  //Users
  user(id?): Observable<any> {
    return this.http$.get(`${this.api.users}/${id}`).pipe(retry(1));
  }
  getUser(filters?): Observable<any> {
    // delete filters.is_external;
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.users}?${params}`).pipe(retry(1));
  }
  getRequesters(filters?): Observable<any> {
    // delete filters.is_external;
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.requesters}?${params}`).pipe(retry(1));
  }
  getRequestersFieldOfStudy(filters?): Observable<any> {
    // delete filters.is_external;
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.requester_field_of_study}?${params}`).pipe(retry(1));
  }

  getDepartments(): Observable<any> {
    return this.http$.get(`${this.api.departments}`).pipe(retry(1));
  }

  getCommittees(): Observable<any> {
    return this.http$.get(`${this.api.committees}`).pipe(retry(1));
  }

  getSkill(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.skills_from_all_user}?${params}`).pipe(retry(1));
  }

  getFieldOfStudy(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$
      .get(`${this.api.field_of_study}?${params}`)
      .pipe(retry(1));
  }
  getFieldOfStudyByFaculty(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$
      .get(`${this.api.field_of_study_bu_faculty}?${params}`)
      .pipe(retry(1));
  }
}
