import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: '[core-menu-horizontal-item]',
  templateUrl: './item.component.html',
})
export class CoreMenuHorizontalItemComponent {
  @Input() item: any;
  @Input() currentUrl: any;
  request_menu_count: any;

  @Input() set notification_menu(value) {
    this.request_menu_count = value;
  }
}
