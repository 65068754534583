<ngx-file-drop class="inline-mode" [ngClass]="{'file-drop-over': hasDropZoneOver}" (onFileOver)="hasDropZoneOver = true" (onFileLeave)="hasDropZoneOver = false" (onFileDrop)="dropped($event)" [multiple]="multiple" [disabled]="form.disabled" accept=".pdf">
<!-- <ngx-file-drop class="inline-mode" [ngClass]="{'file-drop-over': hasDropZoneOver}" (onFileOver)="hasDropZoneOver = true" (onFileLeave)="hasDropZoneOver = false" (onFileDrop)="dropped($event)" [multiple]="multiple" [disabled]="form.disabled"> -->
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <button type=" button" class="btn btn-sm btn-file" (click)="openFileSelector()" [disabled]="form.disabled">
      {{ 'COMPONENT.BROWSE_FILE' | translate }}
    </button>
    <div class="file-display" [ngClass]="{'disabled': form.disabled}">
      <ng-container *ngIf="displayFile; else placeholder">
        <p class="mb-0">{{ displayFile }}</p>
        <a class="file-remove" (click)="removeFile()">
          <span [data-feather]="'x'"></span>
        </a>
      </ng-container>
      <ng-template #placeholder>
        <p class="mb-0 text-muted" *ngIf="!form.disabled">{{ 'APP.FILE_PLACEHOLDER' | translate}} <span class="text-danger">{{ 'APP.FILE_PLACEHOLDER_ADD_ON' | translate}}</span></p>
      </ng-template>
    </div>
  </ng-template>
</ngx-file-drop>
