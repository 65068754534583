import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LabelMode } from 'utils/label-mode.enum';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputComponent), multi: true }],
})
export class InputComponent implements OnInit, OnDestroy, ControlValueAccessor {

  subscription: Subscription;

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    if (value) {
      this.isError = true;
    }
  }

  @Input()
  set valid(value: any) {
    this.isValid = false;
    if (value) {
      this.isValid = true;
    }
  }

  @Input()
  set value(value: any) {
    if (value) {
      this.writeValue(value);
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() type = 'text';
  @Input() autocomplete = 'off';
  @Input() label: string;
  @Input() addonLabel: string = '';
  @Input() labelValue: string = '';
  @Input() placeholder = '';
  @Input() mask: string;
  @Input() dropSpecialCharacters: boolean = false;
  @Input() thousandSeparator: string;
  @Input() specialCharacters: string[] = [];
  @Input() minlength: number = 0;
  @Input() maxlength: number = 1000;
  @Input() tabindex: number = 0;
  @Input() maskPrefix: string = '';
  @Input() maskSuffix: string = '';
  @Input() suffix: string;
  @Input() inputClass: string;
  @Input() classes: string;
  @Input() errorMessage: string = 'VALIDATOR.REQUIRED';
  @Input() showErrorMessage: boolean = true;
  @Input() minVal = null;
  @Input() maxVal = null;

  @Input() debouceTime: number = 0;

  @Input() set labelMode(value: LabelMode) {
    this.mode = value;
  }

  @Output() valueChange = new EventEmitter();

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  form = new FormControl();

  isError = false;
  isValid = false;

  showPwd = false;

  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor() { }

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.pipe(debounceTime(this.debouceTime), distinctUntilChanged()).subscribe((val) => {
      let value = this.form.value;
      if (typeof this.form.value === 'string') {
        value = this.form.value.trim();
      }
      this.valueChange.emit(value);
      this.onChange(value);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(obj: any): void {
    // Use patchValue fot unmatch key
    this.form.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
}
