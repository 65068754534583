import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SweetAlertsService } from 'app/services/sweet-alerts.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-file-upload-multuple',
  templateUrl: './file-upload-multuple.component.html',
  styleUrls: ['./file-upload-multuple.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileUploadMultupleComponent), multi: true }],
})
export class FileUploadMultupleComponent implements OnInit, OnDestroy, ControlValueAccessor {

  subscription: Subscription;

  @Input() name = GenerateRandom(6);
  @Input() label: string;
  @Input() limitLabel: string;
  @Input() limitSize: number = 100000000; // 100MB
  @Input() multiple: boolean = true;

  @Output() valueChange = new EventEmitter();

  files: any = [];

  hasDropZoneOver: boolean = false;

  form = new FormControl();

  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor(
    private translate: TranslateService,
    private swalService: SweetAlertsService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
      const value = this.form.value;
      this.valueChange.emit(value);
      this.onChange(value);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    if (!value && this.files.length > 0) {
      this.removeFile();
    }
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  public dropped(files: NgxFileDropEntry[]) {
    // this.files = [];
    this.hasDropZoneOver = false;
    // const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    // const reader = new FileReader();
    // fileEntry.file((file: File) => {
    //   if (file.size > this.limitSize) {
    //     const title = this.translate.instant('SWEETALERT.WARNING_IMAGE_SIZE_TITLE');
    //     const text = this.translate.instant('SWEETALERT.WARNING_IMAGE_SIZE_TEXT', { value: this.limitLabel['value'], unit: this.limitLabel['unit'] });
    //     this.swalService.swalWarning(title, text);
    //   } else {
    //     reader.readAsDataURL(file);
    //     // reader.onload = () => {
    //     //   this.imageUrl$.next(reader.result);
    //     // };
    //     this.form.setValue(file);
    //   }
    // });
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.files.push(file);
      });
    }
    this.form.setValue(this.files);
  }

  removeFile() {
    this.form.reset();
    this.files = [];
  }
}
