export enum UserRoleId {
  SYS_ADMIN = 1,
  ADMIN = 2,
  STAFF = 3,
  OWNER = 4,
  COMMITTEE = 5,
  GENERAL_COMMITTEE = 6,
}

export enum UserRole {
  SYS_ADMIN = 'System Administration',
  ADMIN = 'Administration',
  STAFF = 'Staff',
  OWNER = 'General',
  COMMITTEE = 'Committee',
  GENERAL_COMMITTEE = 'General&Committee',
}
