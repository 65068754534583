<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="input"></ng-container>
</ng-container>

<ng-template #input>
  <div class="demo-inline-spacing" [ngClass]="classes">
    <div class="custom-control custom-checkbox" [ngClass]="InputClass">
      <input type="checkbox" [id]="name" [name]="name" class="custom-control-input" [formControl]="form"
      (change)="onInputChange($event.target.checked)" />
      <label class="custom-control-label" [for]="name">{{ inputLabel | translate }}</label>
    </div>
  </div>
</ng-template>

<ng-template #default>
  <div class="form-group">
    <label [for]="name">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group form-group-inline">
    <label [for]="name">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>
