import { UserRoleId } from 'utils/user-role';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { NavigationEnd, Router } from '@angular/router';
import { SettingService } from 'app/services/setting.service';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;
  currentUrl: string;
  UserRoleId = UserRoleId

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;
  request_menu_count: any;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _coreMenuService: CoreMenuService,
    private _router: Router,

    private settingService: SettingService,
    private _authService: AuthService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.request_menu_count = JSON.parse(localStorage.getItem('request_menu_count'));
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();
      this._changeDetectorRef.markForCheck();
    });

    // Listen for router events
    this._router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this._unsubscribeAll)).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
      if (this._authService.isAuthenticated()) {
        this.settingService.notificationMenu().subscribe(res => {
          localStorage.setItem('request_menu_count', JSON.stringify(res));
          this.request_menu_count = JSON.parse(localStorage.getItem('request_menu_count'));
        });
      }
    });
  }
}
