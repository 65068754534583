<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            width="110"
          />
        </span>
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appHeader }}</h2> -->
        <!-- <h3 class="mb-0">{{ coreConfig.app.appHeader }}</h3> -->
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content header-brand">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <!-- <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->
    <!--/ Toggle skin -->
    <!-- <p class="version-text ml-1 ml-sm-0">Version: {{ env?.version }}</p> -->

    <!-- Logo left side -->
    <!-- <ul class="nav navbar-nav d-none d-xl-block">
      <li class="nav-item">
        <a class="navbar-brand" [routerLink]="['/']">
          <span class="brand-logo">
            <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="110" />
          </span>
        </a>
      </li>
    </ul> -->

    <!-- Highlight Menu -->
    <li class="nav-item d-none d-lg-flex">
      <!-- <button type="button" class="btn hightlight-menu w-icon mr-75">
        <span [data-feather]="'shopping-bag'" [class]="'mr-50'"></span>
        {{ 'MENU_TITLE.POS' | translate }}
      </button> -->
      <!-- <button type="button" class="btn hightlight-menu w-icon">
        <span [data-feather]="'monitor'" [class]="'mr-50'"></span>
        {{ 'MENU_TITLE.QUEUE_DISPLAY' | translate }}
      </button> -->
    </li>
    <!-- /Highlight Menu -->
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto nav-header-menu">
    <!-- ? Language selection | Uncomment if needed-->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a
        class="nav-link dropdown-toggle d-flex align-items-center"
        id="dropdown-flag"
        ngbDropdownToggle
      >
        <i
          class="flag-icon flag-icon-{{
            languageOptions[_translateService.currentLang].flag
          }}"
        ></i
        ><span class="selected-language">{{
          languageOptions[_translateService.currentLang].title
        }}</span>
      </a>
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a
          *ngFor="let lang of _translateService.getLangs()"
          ngbDropdownItem
          (click)="setLanguage(lang)"
          [ngClass]="lang"
          class="d-flex align-items-center"
        >
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i>
          {{ languageOptions[lang].title }}
        </a>
      </div>
    </li>
    <!--/ Language selection -->

    <!-- Notification -->
    <!-- <app-navbar-notification></app-navbar-notification> -->
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name"
            >{{ "MISC.HELLO" | translate }},
            {{ currentUser?.first_name || "Name" }}</span
          >
          <span class="user-status">{{
            currentUser?.role_name || "Role_Name"
          }}</span>
        </div>
        <span class="avatar">
          <ng-container *ngIf="currentUser?.photo_url">
            <img
              class="round"
              [src]="apiUrl + currentUser?.photo_url"
              alt="avatar"
              height="40"
              width="40"
            />
          </ng-container>
          <ng-container *ngIf="!currentUser?.photo_url">
            <div class="avatar bg-primary">
              <div class="avatar-content">
                <span [data-feather]="'user'"></span>
                <!-- {{
                  currentUser?.first_name + " " + currentUser?.last_name
                    | initials
                }} -->
              </div>
            </div>
          </ng-container>
          <span class="avatar-status-online"> </span>
        </span>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a ngbDropdownItem routerLink="/profile">
          <span [data-feather]="'user'" [class]="'mr-50'"></span
          >{{ "APP.ACCOUNT_PROFILE" | translate }}
        </a>
        <a ngbDropdownItem (click)="logout()">
          <span [data-feather]="'power'" [class]="'mr-50'"></span
          >{{ "LOGIN.SIGN_OUT" | translate }}
        </a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
