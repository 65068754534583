import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserRoleId } from 'utils/user-role';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  currentUser:any
  constructor(
    public router: Router,
    private authService: AuthService,
    ) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const roles = route.data.roles;
    if (this.authService.isAuthenticated()) {
      if (this.authService.userHasRole(roles)) {
        return true;
      } else {
        this.navigate(this.authService.currentUserValue['role_id']);
        return false;
      }
    } else {
      this.authService.logout();
      return false;
    }
  }

  navigate(role_id) {
    switch (role_id) {
      case UserRoleId.OWNER:
        this.router.navigate(['/assessment-requests']);
        break;
      case UserRoleId.STAFF:
      case UserRoleId.COMMITTEE:
      case UserRoleId.GENERAL_COMMITTEE:
        this.router.navigate(['/requests']);
        break;
    }
  }
}
