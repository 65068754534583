import { Component, forwardRef, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LabelMode } from 'utils/label-mode.enum';
import { GenerateRandom } from 'utils/random';
import { NgbDateStruct, NgbDateParserFormatter, NgbDate, NgbCalendar, NgbCalendarBuddhist } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import moment from 'moment';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? String(date.day).padStart(2, '0') + this.DELIMITER + String(date.month).padStart(2, '0') + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerComponent), multi: true },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbCalendar, useClass: NgbCalendarBuddhist },
  ],
})
export class DatepickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  subscription: Subscription;

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    if (value) {
      this.isError = true;
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() label: string;
  @Input() addonLabel: string = '';
  @Input() placeholder = '';
  @Input() errorMessage: string = 'VALIDATOR.REQUIRED';
  @Input() disabledDate;
  @Input() minYear = 1900;
  @Input() beYear = 543;
  @Input() classes: string;
  @Input() clearable: boolean = false;

  @Input() set minDate(value) {
    if (value) {
      this.minPDate = new NgbDate(value.getFullYear() + this.beYear, value.getMonth() + 1, value.getDate());
    }
  };

  @Input() set maxDate(value) {
    if (value) {
      this.maxPDate = new NgbDate(value.getFullYear() + this.beYear, value.getMonth() + 1, value.getDate());
    }
  };

  minPDate: any;
  maxPDate: any;

  currentYear = moment().add(543, 'years').year();

  @Input() set labelMode(value: LabelMode) {
    this.mode = value;
  }

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  form = new FormControl();

  isError = false;

  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor() { }

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.subscribe(value => {
      if (value && value.year) {
        if (value.year - this.beYear >= this.minYear) {
          const year = value.year - this.beYear;
          const month = String(value.month).padStart(2, '0');
          const day = String(value.day).padStart(2, '0');
          const pvalue = String(new Date([year, month, day].join("-")));
          this.onChange(pvalue);
        }
      }
      if(value === null){
        this.onChange(value);
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(obj: any): void {
    let date: NgbDate;
    if (obj) {
      const pdate = new Date(obj);
      date = new NgbDate(pdate.getFullYear() + this.beYear, pdate.getMonth() + 1, pdate.getDate());
    }
    this.form.setValue(date);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
}
