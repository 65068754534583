<ng-container [formGroup]="form">

  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
    <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
    <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="input"></ng-container>
  </ng-container>

  <ng-template #input>
    <ng-container [ngSwitch]="direction">
      <ng-container *ngSwitchCase="'ltr'" [ngTemplateOutlet]="ltr"></ng-container>
      <ng-container *ngSwitchCase="'rtl'" [ngTemplateOutlet]="rtl"></ng-container>
    </ng-container>
    <ng-container [ngTemplateOutlet]="error"></ng-container>
  </ng-template>

  <ng-template #inputEl>
    <ng-container *ngIf="mask">
      <input class="form-control" [ngClass]="{'is-invalid': isErrorInput}" [type]="type" [id]="name" [name]="name" [placeholder]="placeholder | translate" [formControlName]="prefix[1]" [mask]="mask" [dropSpecialCharacters]="dropSpecialCharacters" [thousandSeparator]="thousandSeparator" [minlength]="minlength" [maxlength]="maxlength" />
    </ng-container>
    <ng-container *ngIf="!mask">
      <input class="form-control" [ngClass]="{'is-invalid': isErrorInput}" [type]="type" [id]="name" [placeholder]="placeholder | translate" [formControlName]="prefix[1]" />
    </ng-container>
  </ng-template>

  <ng-template #selectEl>
    <ng-select [ngClass]="{'error': isErrorSelect}" [id]="selectName" [items]="options" [formControlName]="prefix[0]" bindLabel="{{ bindLabel }}" bindValue="{{ bindValue }}" [placeholder]="selectPlaceholder | translate" [searchable]="searchable" [clearable]="true" class="hide-remove"></ng-select>
  </ng-template>

  <ng-template #ltr>
    <div class="input-group input-group-separate {{inputClass}}">
      <div class="input-group-prepend">
        <ng-container [ngTemplateOutlet]="selectEl"></ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="inputEl"></ng-container>
    </div>
  </ng-template>

  <ng-template #rtl>
    <div class="input-group input-group-separate {{inputClass}}">
      <ng-container [ngTemplateOutlet]="inputEl"></ng-container>
      <div class="input-group-append">
        <ng-container [ngTemplateOutlet]="selectEl"></ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #default>
    <div class="form-group">
      <label [for]="name">{{ label | translate }}</label>
      <ng-container [ngTemplateOutlet]="input"></ng-container>
    </div>
  </ng-template>

  <ng-template #inline>
    <div class="form-group form-group-inline">
      <label [for]="name">{{ label | translate }}</label>
      <ng-container [ngTemplateOutlet]="input"></ng-container>
    </div>
  </ng-template>

  <ng-template #error>
    <span class="error" *ngIf="isErrorSelect || isErrorInput">{{ errorMessage | translate }}</span>
  </ng-template>

</ng-container>