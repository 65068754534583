import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from 'app/services/auth.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  apiUrl = '';
  constructor(private router: Router, private authService: AuthService) { }

  getApiUrl() {
    if (this.apiUrl) {
      return this.apiUrl;
    }
    this.apiUrl = environment.apiUrl;
    return this.apiUrl;
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      return req.clone({ setHeaders: { Authorization: token } });
    } else {
      return req.clone({});
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('assets/') > -1) {
      return next.handle(req);
    }

    const url = this.getApiUrl();
    const reqUrl = url + req.url;

    req = req.clone({
      url: reqUrl,
      setHeaders: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
    return next.handle(this.addToken(req, this.authService.getToken())).pipe(
      map((event: any) => {
        return event;
      }),
      catchError((err, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.authService.logout();
            // this.router.navigateByUrl('/auth');
          }
          return throwError(err);
        }
      })
    );
  }
}
