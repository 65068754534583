import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertsService {
  constructor(private translate: TranslateService) {}

  swalConfirmation(
    title?: string,
    text?: string,
    confirmButtonText?: string,
    cancelButtonText?: string
  ) {
    const promise = new Promise((resolve, reject) => {
      Swal.fire({
        title: title || this.translate.instant('SWEETALERT.CONFIRMATION_TITLE'),
        text: text || this.translate.instant('SWEETALERT.CONFIRMATION_TEXT'),
        icon: 'info',
        showCancelButton: true,
        heightAuto: false,
        showClass: {
          popup: 'animate__animated animate__fadeInUp',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown',
        },
        // showLoaderOnConfirm: true,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText:
          confirmButtonText || this.translate.instant('COMPONENT.CONFIRM'),
        cancelButtonText:
          cancelButtonText || this.translate.instant('COMPONENT.CANCEL'),
        onOpen: () => Swal.getConfirmButton().blur(),
      }).then((result) => {
        if (result.value) {
          resolve(true);
        }
      });
    });
    return promise;
  }
  swalConfirmationCustom(
    title?: string,
    text?: string,
    confirmButtonText?: string,
    cancelButtonText?: string
  ) {
    const promise = new Promise((resolve, reject) => {
      Swal.fire({
        title: title || this.translate.instant('SWEETALERT.CONFIRMATION_TITLE'),
        text: text || this.translate.instant('SWEETALERT.CONFIRMATION_TEXT'),
        icon: 'info',
        showCancelButton: true,
        heightAuto: false,
        showClass: {
          popup: 'animate__animated animate__fadeInUp',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown',
        },
        // showLoaderOnConfirm: true,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText:
          confirmButtonText || this.translate.instant('COMPONENT.CONFIRM'),
        cancelButtonText:
          cancelButtonText || this.translate.instant('COMPONENT.CANCEL'),
        onOpen: () => Swal.getConfirmButton().blur(),
      }).then((result) => {
        if (result.value) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  }

  swalConfirmDelete(
    title?: string,
    text?: string,
    confirmButtonText?: string,
    cancelButtonText?: string
  ) {
    const promise = new Promise((resolve, reject) => {
      Swal.fire({
        title: title || this.translate.instant('SWEETALERT.CONFIRMATION_TITLE'),
        text:
          text || this.translate.instant('SWEETALERT.CONFIRMATION_DELETE_TEXT'),
        icon: 'warning',
        showCancelButton: true,
        heightAuto: false,
        showClass: {
          popup: 'animate__animated animate__fadeInUp',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown',
        },
        // showLoaderOnConfirm: true,
        customClass: {
          confirmButton: 'btn btn-warning',
        },
        confirmButtonText:
          confirmButtonText || this.translate.instant('COMPONENT.CONFIRM'),
        cancelButtonText:
          cancelButtonText || this.translate.instant('COMPONENT.CANCEL'),
        onOpen: () => Swal.getConfirmButton().blur(),
      }).then((result) => {
        if (result.value) {
          resolve(true);
        }
      });
    });
    return promise;
  }

  swalWarning(title?: string, text?: string) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: title || this.translate.instant('SWEETALERT.WARNING_TITLE'),
        text: text || this.translate.instant('SWEETALERT.WARNING_TEXT'),
        showConfirmButton: true,
        heightAuto: false,
        showClass: {
          popup: 'animate__animated animate__fadeInUp',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown',
        },
        customClass: {
          confirmButton: 'btn btn-light',
        },
        confirmButtonText: this.translate.instant('COMPONENT.CLOSE'),
        onOpen: () => Swal.getConfirmButton().blur(),
      }).then((_) => {
        resolve(true);
      });
    });
  }

  swalSuccess(title?: string, text?: string) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'success',
        title: title || this.translate.instant('SWEETALERT.SUCCESS_TITLE'),
        text: text || this.translate.instant('SWEETALERT.SUCCESS_TEXT'),
        showConfirmButton: false,
        heightAuto: false,
        showClass: {
          popup: 'animate__animated animate__fadeInUp',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown',
        },
        timer: 2000,
      }).then((_) => {
        resolve(true);
      });
    });
  }

  swalError(title?: string, text?: string) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'error',
        title: title || this.translate.instant('SWEETALERT.ERROR_TITLE'),
        text: text || this.translate.instant('SWEETALERT.ERROR_TEXT'),
        showConfirmButton: false,
        heightAuto: false,
        showClass: {
          popup: 'animate__animated animate__fadeInUp',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown',
        },
        timer: 2000,
      }).then((_) => {
        resolve(true);
      });
    });
  }
}
